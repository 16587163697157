
<template>

  <v-app-bar
      color="teal-darken-4"
      dense
      elevate-on-scroll
      rounded
      class="elevation-12 gradient-app-bar"
    >
      <!-- Image Slot with Gradient -->
      <template v-slot:image>
        <v-img 
          gradient="to top right, rgba(19,84,122,.8), rgba(128,208,199,.8)"
          
          class="absolute w-100 h-100"
          src="https://picsum.photos/1920/1080?random"
          alt="Header Background"
          cover
        ></v-img>
      </template>
  
      <!-- Navigation Icon -->
      <template v-slot:prepend>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="white" />
      </template>
  
      <!-- Title -->
      <v-app-bar-title class="text-white font-weight-bold">
        Mvids 
      </v-app-bar-title>
  
      <v-spacer></v-spacer>
      
       <!-- Menu Button with Icon -->
       <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="tonal" class="btnLaunch" v-on:click="LaunchJellyFin()">
            <v-icon size="x-large" color="white" >mdi-jellyfish</v-icon>
            Launch
          </v-btn>
        </template>
       </v-menu>
       
        <!-- Menu Button with Icon -->
        <v-btn class="text-none ml-2" stacked v-on:click="dialog=true">
        <v-badge color="error" :content="unread_messages">
          <v-icon>mdi-bell-outline</v-icon>
        </v-badge>        
    </v-btn>
       

      <v-menu>
        
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props">
            <v-icon color="white">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
  
        <!-- Dropdown Menu Items -->
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            @click="handleClick(index)"
          >
            <v-list-item-title class="font-weight-medium" style="color:#000000;">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    
    
    <v-navigation-drawer
      v-model="drawer"
      location="left"
      temporary
      class="gradient-drawer elevation-16"
      width="250"
    >
      <v-list dense nav>
  
        <!-- Drawer Header -->
        <v-list-item class="py-3 px-4">
          <v-icon color="white" class="mr-3">mdi-account-circle</v-icon>
          <v-list-item-content>
            <v-list-item-title class="text-white font-weight-bold text-h6 ml-8" style="margin-top:-18px">
              {{ user.name }}
            </v-list-item-title>
            <v-list-item-subtitle class="text-white-50 mt-1" >Your Dashboard</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
  
        <v-divider class="mx-3 my-2"></v-divider>
  
        <!-- Navigation Items with Icons -->
        <v-list-item
          v-for="(item, index) in drawerItems"
          :key="index"
          class="drawer-item"
          @click="handleDrawerClick(index)"
        >
          <v-list-item-icon class="d-block mx-auto">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="font-weight-medium ml-8" style="margin-top:-18px">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  
<!-- Dialog -->
<v-dialog v-model="dialog" max-width="420px" transition="dialog-fade-transition">
  <v-card class="rounded-xl gradient-card elevation-12" >
    <!-- Header -->
    <v-card-title class="d-flex align-center px-4 py-3">
      <v-icon color="white" size="30">mdi-bell-ring</v-icon>
      <span class="ml-3 text-h5 text-white">Notifications</span>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="dialog = false">
        <v-icon color="black">mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <!-- Notification List -->
    <v-card-text class="px-4 py-4" >
      <v-container fluid>
        <v-row dense>
          <v-col cols="12" v-if="messages.length<=0">
            <v-card
              class="notification-item rounded-lg d-flex align-center"
              outlined
              :class="unread"
              
            >
              <!-- Notification Icon -->
              <v-avatar class="mr-3">
                <v-icon color="gray">
                  mdi-eye-check
                </v-icon>
              </v-avatar>
                 <!-- Notification Text -->
                 <v-card-text class="flex-grow-1">
                <h5 class="title">No New Notifications</h5>
                <p class="message text--secondary"></p>
                
              </v-card-text>

            </v-card>
          </v-col>
          <v-col v-else v-for="msg in messages" :key="msg.id" cols="12">
            <v-card
              class="notification-item rounded-lg d-flex align-center"
              outlined
              :class="{'unread': msg.seen === 0, 'read': msg.seen !== 0}"
              
            >
              <!-- Notification Icon -->
              <v-avatar class="mr-3">
                <v-icon :color="msg.seen == 0 ? 'red' : 'gray'">
                  {{ msg.seen == 0 ? 'mdi-bell-alert' : 'mdi-eye-check' }}
                </v-icon>
              </v-avatar>

              <!-- Notification Text -->
              <v-card-text class="flex-grow-1">
                <h5 class="title">{{ msg.title }}</h5>
                <p class="message text--secondary">{{ msg.text }}</p>
                <small class="post-date text--disabled">{{ formatDate(msg.post_date) }}</small>
              </v-card-text>

              <!-- Action Button -->
              <v-btn icon @click.stop="dismissMsg(msg.id, msg)" variant="flat">
                <v-icon color="green">mdi-read</v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <!-- Footer -->
    <v-card-actions class="px-4 py-2 justify-center">
      <v-btn text color="white" @click="dismissMsg(0, undefined)">Dismiss All</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>


  </template>
  
  <style scoped>
  .gradient-app-bar {
    background: linear-gradient(135deg, rgba(19, 84, 122, 0.85), rgba(128, 208, 199, 0.85));
  }
  
  .v-btn {
    transition: all 0.2s ease;
  }
  
  .v-btn:hover {
    transform: scale(1.1);
  }
  
  .v-app-bar-title {
    font-size: 1.5rem;
    letter-spacing: 0.5px;
  }
  
  
  .gradient-drawer {
    background: linear-gradient(135deg, rgba(19, 84, 122, 0.95), rgba(128, 208, 199, 0.9));
    color: white;
  }
  
  .drawer-item {
    transition: all 0.2s ease;
  }
  
  .drawer-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
  }
  
  .v-list-item-icon {
    color: white;
  }
  
  .v-list-item-title {
    color: white;
    letter-spacing: 0.5px;
  }
  
  .v-divider {
    border-color: rgba(255, 255, 255, 0.2);
  }
  </style>

<script>
import { useUserStore } from '../stores/user'
import router from '@/router';
import axios from "axios";
import { ref } from 'vue'
export default {

  setup() {
    const user = useUserStore();
    
    window.store = {user};
    const DiscordUrl = ref("");
    const visible = false;
    const dialog = ref(false);
    const  drawer = ref(false);
    const new_messages= ref(0);
    const messages = ref({new_messages:0, messages:{}});
    const unread_messages = ref(0);
    const  items =  [
        { title: 'Account',
          click(){
            router.push("/account")          
          },
          
        },
        { title: 'Sign Out',
          click(){
            user.signOut();            
          },
          
        }];
    const drawerItems =  [    
        {
          title: 'Dashboard',
          value: 'home',
          icon: 'mdi-view-dashboard',
          click(){
            router.push("/")
          }
        }, 
        {
          title: 'Invite',
          value: 'invite',
          icon: 'mdi-account-plus',
          click(){
            router.push("/invite")
          }
        },   
        {
          title: 'Live TV',
          value: 'live_tv',
          icon: 'mdi-remote-tv',
          click(){
            router.push("/live_tv")
          }
        }, 
        {
          title: 'Server',
          value: 'server',
          icon: 'mdi-jellyfish',
          click(){
            router.push("/server")
          }
        },
        {
          title: 'Billing',
          value: 'billing',
          icon: 'mdi-credit-card',
          click(){
            router.push("/billing")
          }
        },
        {
          title: 'Support',
          value: 'support',
          icon: 'mdi-message-text',
          click(){
            window.open(DiscordUrl.value,'_blank');  
          }
        }];

      if(user.role==9)
      {
        drawerItems.push( {
          title: 'Admin',
          value: 'admin',
          icon: 'mdi-shield-account',
          click(){
            router.push("/admin")
          }
        });
      }
      
       getMessages(user.id);
       getSiteSetting();
      console.log("Messages",messages.value);
      function handleClick (index){
        items[index].click.call(this)
      }
      function handleDrawerClick (index){
        drawerItems[index].click.call(this)
      }
      function LaunchJellyFin()      
      {
        for(const item of user.servers)
        {          
          window.open(item.URL,'_blank');          
          break;
        }      
      }
     function formatDate(date) {
      const now = new Date();
      
      // Ensure the server date is in UTC format
      const messageDate = new Date(date); // Assume 'date' is in UTC (ISO 8601 format)
      
      // Convert message date to local time
      const localMessageDate = messageDate.toLocaleString(); // Automatically converts to the client's time zone

      // Convert to a Date object again after conversion to local time
      const localDate = new Date(localMessageDate);

      // Ensure the message date is not in the future
      if (localDate > now) {
        return "Just now"; // Can be customized
      }

      const diffInSeconds = Math.floor((now - localDate) / 1000);

      if (diffInSeconds < 60) return `${diffInSeconds} seconds ago`;

      const diffInMinutes = Math.floor(diffInSeconds / 60);
      if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;

      const diffInHours = Math.floor(diffInMinutes / 60);
      if (diffInHours < 24) return `${diffInHours} hours ago`;

      const diffInDays = Math.floor(diffInHours / 24);
      if (diffInDays < 7) return `${diffInDays} days ago`;

      const diffInWeeks = Math.floor(diffInDays / 7);
      return diffInWeeks < 5 ? `${diffInWeeks} weeks ago` : `${Math.floor(diffInDays / 30)} months ago`;
    }


      function getMessages(user_id)
      {
        const { id, token, email} = user; // Extract only needed fields
        
      
        let UserData = {
                user_id:user_id,    
                user: { id, email, token },
            }                        
            axios.post("https://mediatrunk.net/mobilevids/my_messages.php", UserData)
            .then(response => {
                
                messages.value = response.data.messages;
                unread_messages.value = response.data.messages.filter((msg) => msg.seen == 0).length;
                
                new_messages.value = response.data.new_messages;                
                if(new_messages.value>0)
                  dialog.value = true;                
                
            })
            .catch(error => {
               
                console.error("There was an error!", error);
            });
      }
      function getSiteSetting()
    {
      axios.post("https://mediatrunk.net/mobilevids/sitesettings.php")
          .then(response => {
                            
              DiscordUrl.value =  response.data.DISCORD_URL;                    
          })
          .catch(error => {
              
              console.error("There was an error!", error);
          });
    }

      function dismissMsg(message_id,msg)
      {
        const { id, token, email} = user; // Extract only needed fields
        
        let UserData = {
                user_id:user.id,  
                message_id:message_id,
                user: { id, email, token },

            }                        
            axios.post("https://mediatrunk.net/mobilevids/dismiss_message.php", UserData)
            .then(response => {                
                if(msg!=undefined)
                msg.seen = 1;
                dialog.value = false;                
                getMessages(user.id);
                console.log(response);
                
            })
            .catch(error => {
               
                console.error("There was an error!", error);
            });
      }

    return{ 
      // Properties
      visible,drawer,new_messages,messages,unread_messages,user,items,drawerItems,dialog,DiscordUrl,
      // FUnctions
      handleClick,handleDrawerClick,LaunchJellyFin,getMessages,formatDate,dismissMsg,getSiteSetting          
    }
  },  

}
</script>
<style scoped>
.gradient-card {
  background: linear-gradient(135deg, #3a1c71, #d76d77, #ffaf7b);
  color: white;
}
.btnLaunch{
  background: linear-gradient(135deg, #693bbe, #a11095, #77685f);
}
.notification-item {
  padding: 12px;
  transition: all 0.3s ease;
}

.notification-item.unread {
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.notification-item.read {
  background-color: #f5f5f5;
  
  
}

.notification-item.read .title,
.notification-item.read .message {
  color: #757575;
}

.title {
  font-weight: bold;
}

.post-date {
  margin-top: 4px;
  font-size: 12px;
  color: #999;
}
</style>
